import React from "react"
import Layout from '../components/layout'
import { Helmet } from "react-helmet"
import { graphql, useStaticQuery } from 'gatsby'
import { InlineWidget } from "react-calendly";
import PrimaryTestimonial from "../components/primaryTestimonial";

const BookDemoPage = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          pages {
            bookDemo {
              title
              description
              url
            }
          }
        }
      }
    }
  `);

  const testimonialPeople = ["alexis", "brie", "jayna", "maegan"]
  const testimonialPerson = testimonialPeople[Math.floor(Math.random()*testimonialPeople.length)]

  return (
    <>
      <Helmet>
        {/* Meta */}
          <html lang="en" />
          <title>{data.site.siteMetadata.pages.bookDemo.title}</title>
          <meta charSet="utf-8" />
          <meta name="title" content={`Features - ${data.site.siteMetadata.title}`}/>
          <meta name="description" content={data.site.siteMetadata.pages.bookDemo.description} /> 
          <link rel="canonical" href={data.site.siteMetadata.pages.bookDemo.url} />
      </Helmet>
      <Layout>
        <div className="bg-white">
            <div className="px-4 pt-8 mx-auto max-w-7xl sm:px-6 lg:px-8">
                <div className="text-center">
                    <h1 className="text-base font-semibold tracking-wide text-indigo-600 uppercase">Batch Demonstration</h1>
                    <h2 className="mt-1 text-3xl font-extrabold text-gray-900 sm:text-4xl sm:tracking-tight lg:text-5xl">
                      <span className="text-peach-500 md:bg-clip-text md:text-transparent md:bg-gradient-to-r md:to-peach-500 md:from-indigo-700">
                        You won't believe it until we show you
                      </span>
                    </h2>
                    <p className="max-w-2xl mx-auto mt-5 text-xl text-gray-500">Book a 30-minute demo to learn how Batch increases sales, decreases abandonment and delivers 1,000% ROI for its customers.</p>
                </div>
                <InlineWidget url="https://calendly.com/batch-demo/30min" />
            </div>
        </div>
        <PrimaryTestimonial person={testimonialPerson} />
        
      </Layout>  
    </>
  )
}

export default BookDemoPage
